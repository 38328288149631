import React from 'react';
import {
  MDBCol, MDBIcon, MDBModal, MDBModalBody, MDBRow,
} from 'mdbreact';
import connect from 'react-redux/es/connect/connect';
import { toggleModal, toggleSecondModalClose } from '../../storage/actions/modalsActions';

const ContactUsModal = ({
  modalState, toggleModal,
}) => {
  const closeModal = () => {
    toggleModal(!modalState.isOpen, 'contactUsModal');
  };

  return (
    <MDBModal
      centered
      className="register_div"
      isOpen={modalState.isOpen}
      toggle={closeModal}
    >
      <MDBRow style={{
        justifyContent: 'flex-end',
        paddingRight: '0px',
      }}
      >
        <MDBIcon
          far
          icon="times-circle"
          onClick={closeModal}
          size="lg"
          className="blue-grey-text"
        />
      </MDBRow>
      <MDBModalBody>
        <MDBRow className="headingdivr">
          <MDBCol>
            <h2>
              Contact Us
            </h2>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol md="12">
            <div className="form-group registeinput">
              <label className="font11bold">
                Hi there, and Welcome to The 209 Group!
              </label>
              <label className="font12">
                Whether you`re a contractor looking for work, or a company looking to hire
                contractors, we`re here to help!
              </label>
              <label className="font12">
                Simply use the online chat bubble in the lower left of your screen to let us know
                how we can assist you.
              </label>
            </div>
          </MDBCol>
        </MDBRow>
      </MDBModalBody>
    </MDBModal>
  );
};

const mapStateToProps = state => ({
  modalState: state.modalState.contactUsModal,
});

export default connect(mapStateToProps, {
  toggleModal,
  toggleSecondModalClose,
})(ContactUsModal);
