import React, { Component } from 'react';
import {
  MDBContainer,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavbarToggler,
  MDBCollapse,
  MDBNavItem,
  MDBNavLink,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBFooter,
  MDBCarousel,
  MDBCarouselInner,
  MDBCarouselItem,
} from 'mdbreact';
import '../App.css';
import { connect } from 'react-redux';
// import { useLocation } from 'react-router-dom';
// import ReactPlayer from 'react-player';
import { sendContractorRate } from '../storage/actions/workOrdersActions';
import { toggleModal } from '../storage/actions/modalsActions';
import iosBadge from '../assets/App_Store_Badge.png';
import androidBadge from '../assets/google-play-badge.png';
import varifyEmail from '../utils/api/post/verifyEmail';
import HomeModal from '../components/modal/homePage';
// import SW9video from '../assets/SourceW9_Video.mp4';

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isNavbarOpen: false,
    };
  }

  componentDidMount() {
    this.initialActions();
    this.sendRate();
  }

  initialActions() {
    const modals = { 'sign-up-modal': 'signUpModal' };
    const hash = window.location.hash.replace('#', '');
    // eslint-disable-next-line no-prototype-builtins
    if (modals.hasOwnProperty(hash)) {
      this.props.toggleModal(true, modals[hash]);
    }

    const queryParams = new URLSearchParams(window.location.search);
    const params = {
      id: queryParams.get('id'),
      type: window.location.href.split('?')[1],
    };

    if (queryParams.get('assigned_work_order')) {
      this.props.toggleModal(true, 'loginModal');
    }
    if (queryParams.get('invite_work_order')) {
      this.props.toggleModal(true, 'loginModal');
    }
    if (queryParams.get('incomplete_work_order')) {
      this.props.toggleModal(true, 'loginModal');
    }

    if (queryParams.get('verify')) {
      varifyEmail(params.id).then((res) => {
        if (!res.success) return;
        this.props.toggleModal(true, 'loginModal', false, { email: res.email });
      });
    }

    if (queryParams.get('SID')) {
      this.props.toggleModal(true, 'signUpModal');
    }

    if (queryParams.get('forgot_pass')) {
      this.props.toggleModal(true, 'resetPassword');
    }
    if (queryParams.get('unsub')) {
      this.props.toggleModal(true, 'unsubscribeFromNotifications');
    }
  }

  sendRate() {
    const params = new URLSearchParams(window.location.search);
    const typeParameter = params.get('type');

    if (typeParameter === 'thank_you_for_rate') {
      this.props.sendContractorRate({
        id: params.get('id'),
        rate: params.get('rate'),
      });
    }
  }

  render() {
    return (
      <div className="mainhomediv">
        <div className="upperhomediv">
          <header>
            <MDBNavbar
              dark
              expand="md"
              scrolling
              fixed="top"
              className="navbarcol"
            >
              <MDBNavbarBrand href="/">
                <h1>
                  <span style={{ color: 'Blue-gray' }}>THE 209</span>
                  <span style={{ color: 'Teal' }}>GROUP</span>
                </h1>
              </MDBNavbarBrand>
              <MDBNavbarToggler onClick={() => this.setState((prevState) => ({
                isNavbarOpen: !prevState.isNavbarOpen,
              }))}
              />
              <MDBCollapse
                isOpen={this.state.isNavbarOpen}
                navbar
                className="navbarcollapse"
              >
                <MDBNavbarNav right className="navbarcoll">
                  <MDBNavItem active>
                    <MDBNavLink to="/home">Home</MDBNavLink>
                  </MDBNavItem>
                  <MDBNavItem
                    className="nav-link Ripple-parent navitems active"
                    onClick={() => this.props.toggleModal(true, 'contactUsModal')}
                  >
                    Contact Us
                  </MDBNavItem>
                  <MDBNavItem
                    className="navitem nav-link Ripple-parent active"
                    onClick={() => this.props.toggleModal(true, 'loginModal')}
                  >
                    Sign In
                  </MDBNavItem>
                  <MDBNavItem
                    className="navitem nav-link Ripple-parent active"
                    onClick={() => this.props.toggleModal(true, 'signUpModal')}
                  >
                    Sign Up
                  </MDBNavItem>
                </MDBNavbarNav>
              </MDBCollapse>
            </MDBNavbar>
          </header>
          <MDBContainer
            className="home_div1 text-center pt-5"
            fluid
            style={{
              marginLeft: '0px',
              marginRight: '0px',
              paddingLeft: '0px',
              paddingRight: '0px',
            }}
          >
            <MDBContainer>
              <MDBCarousel
                className="firsthomecor"
                activeItem={1}
                length={1}
                slide
                showControls={false}
                showIndicators={false}
                multiItem
                style={{ marginTop: '10px' }}
              >
                <MDBCarouselInner>
                  <MDBRow>
                    <MDBCarouselItem itemId="1">
                      <div className="caroudiv">
                        <MDBRow className="center">
                          <MDBCol>
                            <h1 style={{ marginBottom: '30px' }}>
                              Looking for contract work?
                              <br />
                              Looking for contractors?
                            </h1>
                          </MDBCol>
                        </MDBRow>
                        <MDBRow className="justify-content-center">
                          <MDBCol className="col-5">
                            <div style={{ marginTop: '10px', paddingTop: '10px', paddingBottom: '10px' }}>
                              209G is the
                              {' '}
                              <span style={{ fontWeight: 'bolder' }}>next generation</span>
                              {' '}
                              FMS platform for connecting
                              contractors and clients.
                            </div>
                            <div style={{ paddingBottom: '5px' }}>
                              {' '}
                              209G is
                              {' '}
                              <span style={{ fontWeight: 'bolder' }}>free for contractors</span>
                              {' '}
                              and
                              {' '}
                              <span style={{ fontWeight: 'bolder' }}>free for clients to search and contact</span>
                              {' '}
                              contractors.

                            </div>
                            <div style={{ paddingBottom: '5px' }}>
                              {' '}
                              209G has a
                              {' '}
                              <b>transparent fee structure</b>
                              {' '}
                              for clients needing 209G work order and pay processing,
                              1099 administration, contractor vetting and onboarding,
                              or full project deployment management support.
                            </div>
                            <MDBRow style={{ justifyContent: 'center', marginTop: '20px' }}>
                              <a href="https://apps.apple.com/us/app/id1523678488">
                                <img src={iosBadge} alt="iOS App Store Badge" />
                              </a>
                              <a href="https://play.google.com/store/apps/details?id=com.sw9">
                                <img
                                  src={androidBadge}
                                  alt="Android Google Play Store Badge"
                                />
                              </a>
                            </MDBRow>
                            <MDBRow style={{ backgroundColor: 'rgba(0, 0, 0, 0.3', padding: '5px' }}>
                              <h5 style={{ fontWeight: 'bold' }}>The 209 Group Client:</h5>
                              <p>
                                &ldquo;Professionalism, friendliness, great communication and a
                                platform that works well!&rdquo;
                              </p>

                              <h5 style={{ fontWeight: 'bold', marginTop: '15px' }}>The 209 Group Client:</h5>
                              <p>
                                &ldquo;AMAZING service.  I am so glad we work with them.
                                All our contractors are able to access their scope of work
                                with everything in one place.  They also help manage all
                                my 1099 tracking and I can pay the contractors right from
                                this portal.&rdquo;`
                              </p>
                            </MDBRow>
                          </MDBCol>
                          <MDBCol className="col-5">
                            <MDBBtn
                              color="success"
                              className="signbutton"
                              type="submit"
                              onClick={() => this.props.toggleModal(true, 'signUpModal')}
                            >
                              Sign up
                            </MDBBtn>
                            <MDBBtn
                              color="info"
                              className="signbutton"
                              onClick={() => this.props.toggleModal(true, 'loginModal')}
                            >
                              Sign in
                            </MDBBtn>
                            {/* <div>Why The 209 Group?  Watch the Video Below!</div>
                            <ReactPlayer
                              url={SW9video}
                              controls
                              width="480px"
                              height="200"
                            /> */}
                            <MDBRow style={{
                              justifyContent: 'left', marginTop: '20px', backgroundColor: 'rgba(0, 0, 0, 0.4)', marginLeft: '3px', padding: '5px',
                            }}
                            >
                              <h5 style={{ fontWeight: 'bold' }}>The 209 Group Contractor:</h5>
                              <p>
                                Man do I love your platform!  Been beating on it now
                                going into my 4th month and just could not be happier!
                              </p>
                              <h5 style={{ fontWeight: 'bold', marginTop: '15px' }}>The 209 Group Contractor:</h5>
                              {' '}
                              <p>
                                The The 209 Group system was fast and effective and we
                                received our payments in a timely manner.
                              </p>
                            </MDBRow>
                          </MDBCol>
                        </MDBRow>
                      </div>
                    </MDBCarouselItem>
                  </MDBRow>

                </MDBCarouselInner>
              </MDBCarousel>
            </MDBContainer>
          </MDBContainer>
        </div>
        <MDBContainer
          className="text-center"
          fluid
          style={{
            marginLeft: '0px',
            marginRight: '0px',
            paddingLeft: '0px',
            paddingRight: '0px',
          }}
        >
          <div className="home_page">
            <MDBContainer className="home_div4">
              <MDBRow
                className="justify-content-center"
                style={{ paddingTop: '20px', paddingBottom: '20px' }}
              >
                <div className="how_head">
                  <h1>How The 209 Group Works</h1>
                </div>
                <MDBContainer>
                  <MDBCol className="justify-content-center" />
                </MDBContainer>
                <div className="d-flex div4div">
                  <MDBCol className="justify-content-center div4col1">
                    <img
                      alt="notebook"
                      src="../../notebook.svg"
                      className="img3"
                    />
                    <h5>DESCRIBE YOUR WORK</h5>
                    <p className="text_div3">
                      This helps contractors determine if they&apos;re a match for
                      your work order.
                    </p>
                  </MDBCol>
                  <MDBCol className="justify-content-center div4col2">
                    <img
                      alt="professions-and-jobs"
                      src="../../professions-and-jobs.svg"
                      className="img3"
                    />
                    <h5>REVIEW &amp; ASSIGN CONTRACTOR</h5>
                    <p className="text_div3">
                      Review the applicants profile and assign the the one that
                      best fits your work
                    </p>
                  </MDBCol>
                  <MDBCol className="justify-content-center div4col3">
                    <img alt="light" src="../../light.svg" className="img3" />
                    <h5>RELAX</h5>
                    <p className="text_div3">
                      Kick back and relax knowing you have a quality contractor
                      doing your work
                    </p>
                  </MDBCol>
                </div>
              </MDBRow>
            </MDBContainer>
          </div>
          <MDBFooter
            color="brown darken-1"
            className="font-small pt-4  paddingresponsive fixed-bottom"
            fixed="bottom"
          >
            <div className="container-fluid footer-copyright py-3">
              <hr className="my-2 hr_tag" />
              <MDBContainer
                className="text-center icongroupres"
                fluid
                style={{ color: 'white', paddingBottom: '20px' }}
              >
                Copyright &copy;
                {` ${new Date().getFullYear()} `}
                <b>The 209 Group</b>
                . All Rights Reserved.
              </MDBContainer>
            </div>
          </MDBFooter>
        </MDBContainer>
        <HomeModal mainContainer={this.props.mainContainer.current} />
      </div>

    );
  }
}

const mapDispatchToProps = {
  sendContractorRate,
  toggleModal,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});
export default connect(mapStateToProps, mapDispatchToProps)(Home);
