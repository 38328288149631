import React, { useEffect } from 'react';
import {
  MDBIcon,
  MDBNavbar,
  MDBNavbarNav,
  MDBNavItem, MDBNavLink,
} from 'mdbreact';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import { logoutUser } from '../../../storage/actions/authActions';
import { toggleModal } from '../../../storage/actions/modalsActions';

const Header = ({
  profile, toggleModal, openSidebar, logoutUser,
}) => {
  const { shutdown, update } = useIntercom();
  useEffect(() => {
    if (profile?.name) {
      update({
        user: profile.name,
        email: profile.email,
        phone: profile.phone,
        created_at: new Date(),
        customAttributes: {
          name: profile.name,
          company_name: profile?.company?.name,
          user_id: profile.unique_id,
          w9_user_id: profile.unique_id,
          user_type: profile.userType,
        },
      });
    }
  }, [profile]);

  const logOut = () => {
    logoutUser(shutdown);
  };

  return (
    <div className="navbartop" style={{ height: '48px' }}>
      <MDBNavbar double expand="md" fixed="top" scrolling className="navbartop" style={{ height: '48px' }}>
        <MDBNavbarNav left className="navbartopbrand">
          <MDBNavItem>
            <div
              key="sideNavToggleA"
              style={{
                lineHeight: '32px',
                verticalAlign: 'middle',
              }}
            >
              <MDBIcon
                icon="bars"
                style={{ color: 'white', marginRight: '10px' }}
                color="white"
                onClick={() => openSidebar(true)}
              />
            </div>
          </MDBNavItem>
          <MDBNavItem className="d-none d-md-inline " style={{ paddingTop: '5px' }}>
            <MDBNavLink
              className="no-paddings"
              to={
                profile.userType === 'contractor'
                  ? '/dashboard/upcoming-work'
                  : '/dashboard/assigned'
              }
            >
              <strong className="white-text" style={{ fontWeight: 'bold' }}>The 209 Group</strong>
            </MDBNavLink>
          </MDBNavItem>
        </MDBNavbarNav>
        <MDBNavbarNav
          right
          style={{
            WebkitBoxOrient: 'horizontal',
            flexDirection: 'row',
          }}
          className="navbartopnav"
        >
          <MDBNavItem
            style={{
              display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer',
            }}
            onClick={() => toggleModal(true, 'profile')}
          >
            <img
              title="Profile"
              src={profile.image || 'https://www.w3schools.com/css/img_avatar.png'}
              alt="Avatar"
              className="avatar nonoutlineprofile"
            />
          </MDBNavItem>
          <MDBNavItem
            onClick={logOut}
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          >
            <img title="Logout" className="logoutimg" src="/switch@2x.png" alt="" />
          </MDBNavItem>
        </MDBNavbarNav>
      </MDBNavbar>
    </div>
  );
};

const mapStateToProps = state => ({
  profile: state.profile,
});

export default connect(
  mapStateToProps,
  {
    logoutUser,
    toggleModal,
  },
)(withRouter(Header));
